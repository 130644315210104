import { Link } from "react-router-dom";
import payment from "../../assets/img/random/payment.webp";
import footerLogo from "../../assets/img/logo.png";
import dayjs from "dayjs"
import { useDispatch, useSelector } from "react-redux";
import { homeSelector, settingData } from "../../store/reducer/HomeReducer";
import { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faSquareFacebook, faSquareInstagram, faSquareTwitter } from "@fortawesome/free-brands-svg-icons";
const Footer = () => {

    const dispatch = useDispatch();
    const { settingDatas } = useSelector(homeSelector);

    useEffect(() => {
        // if (localStorage.getItem('isAuthenticated'))
        dispatch(settingData({}))
    }, [])
    console.log(settingDatas, 'settingDatas')
    return (
        <div>
            <footer>
                <div className="footer__area footer-bg">
                    <div className="footer__top pt-40 pb-40">
                    <div className="container">
                            <div className="row align-items-center foot-row">
                                <div className="col-md-4">
                                <div className="footer__widget">
                                                    <div className="footer__widget-title mb-10">
                                                        <div className="footer__logo">
                                                            <a href="index.html"><img src={footerLogo} alt="" /></a>
                                                        </div>
                                                    </div>
                                                    <div className="footer__widget-content">
                                                        <div className="footer__hotline d-flex align-items-center mb-10">
                                                            <div className="icon mr-15">
                                                                <i className="fal fa-headset"></i>
                                                            </div>
                                                            <div className="text">
                                                                <h4>Hotline Free 24/24:</h4>
                                                                <span>(+966) 537664998</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                </div>
                                <div className="col-md-6 foot">
                                <div className="col-md-3">
                                                        <div className="footer__widget">
                                                            <div className="footer__widget-title">
                                                                <h4>Information</h4>
                                                            </div>
                                                            <div className="footer__widget-content">
                                                                <div className="footer__link">
                                                                    <ul>
                                                                        <li><a href="#"> Custom Service </a></li>
                                                                        <li><a href="#">F.A.Q.’s</a></li>
                                                                        <li><a href="#">Ordering Tracking</a></li>
                                                                        <li><a href="#"> Contacts</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="footer__widget">
                                                            <div className="footer__widget-title">
                                                                <h4>Our Services</h4>
                                                            </div>
                                                            <div className="footer__widget-content">
                                                                <div className="footer__link">
                                                                    <ul>
                                                                        <li><a href="#">Sitemap</a></li>
                                                                        <li><a href="#">Privacy Policy</a></li>
                                                                        <li><a href="#">Your Account</a></li>
                                                                        <li><a href="#">Advanced Search</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="footer__widget">
                                                            <div className="footer__widget-title">
                                                                <h4>My Account</h4>
                                                            </div>
                                                            <div className="footer__widget-content">
                                                                <div className="footer__link">
                                                                    <ul>
                                                                        <li><a href="#"> About us </a></li>
                                                                        <li><a href="#">Delivery Information</a></li>
                                                                        <li><a href="#">Privacy Policy</a></li>
                                                                        <li><a href="#">Discount</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="footer__widget">
                                                            <div className="footer__widget-title">
                                                                <h4>Payment & Shipping</h4>
                                                            </div>
                                                            <div className="footer__widget-content">
                                                                <div className="footer__link">
                                                                    <ul>
                                                                        <li><a href="#">Terms Of Use</a></li>
                                                                        <li><a href="#">Payment Methods</a></li>
                                                                        <li><a href="#">Shipping Guide</a></li>
                                                                        <li><a href="#">Locations We Ship To</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer__copyright pt-30 pb-35 footer-bottom-bg">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="social__media">
                                        <a href="#"><FontAwesomeIcon icon={faSquareFacebook} /></a>
                                        <a href="#"><FontAwesomeIcon icon={faSquareInstagram} /></a>
                                        <a href="#"><FontAwesomeIcon icon={faLinkedin} /></a>
                                        <a href="#"><FontAwesomeIcon icon={faSquareTwitter} /></a>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="footer__payment f-right">
                                        <a href="#" className="m-img"><img src={payment} /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="footer__copyright-text">
                            <p>Copyright © <a href="index.html">Medar Al'aqtised Medical Co.</a> All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}


export default Footer;