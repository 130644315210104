import React, { useRef, useState, useEffect, useCallback } from "react";
import Logo from "../../assets/img/logo.png";
import cartMini from "../../assets/img/cart-mini-1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import NiceSelect from "nice-select2";
import { CartSelector, cartProductList } from "../../store/reducer/CartReducer";
import { API_BASE } from "../../services/config";
import AVATAR from "../../assets/img/avatar.webp";
import { toast } from "react-toastify";
import { homeSelector, settingData } from "../../store/reducer/HomeReducer";
import { logOut } from "../../store/reducer/SigninReducer";
import { Button } from "bootstrap";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Visible = localStorage.getItem("isAuthenticated") ?? false;
  const [isOpen, setIsOpen] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);
  const { cartProductDatas, cartProducttotal, cartLoading } =
    useSelector(CartSelector);
  const { settingDatas } = useSelector(homeSelector);
  useEffect(() => {
    let page = 0;
    let limit = 0;
    let query = "";
    if (localStorage.getItem("isAuthenticated")) {
      dispatch(cartProductList({ page, limit, query }));
    }
    dispatch(settingData({}));
  }, []);

  useEffect(() => {
    console.log(cartProductDatas, "cartProductDatas");
  }, [cartProductDatas]);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const logoutuser = () => {
    dispatch(logOut());
  };
  return (
    <div>
      <header>
        <div className="header__area">
          <div className="header__top d-none d-sm-block">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-9 col-lg-9 col-md-9 d-none d-md-block">
                <div className="header__bottom-right d-flex">
                    <div className="header__currency">
                      <select>
                        <option>SAR</option>
                        <option>USD</option>
                      </select>
                    </div>
                    <div className="header__lang d-md-none d-lg-block">
                      <select>
                        <option>English</option>
                        <option>Arabic</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <div className="header__action d-flex justify-content-center justify-content-md-end">
                    <ul>
                      {Visible ? (
                        <>
                          
                          <li>
                            <Link to={"/my_account"}>My Account</Link>
                          </li>
                          <li>
                            <Link to={"/my_orders"}>My Orders</Link>
                          </li>
                          <li>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={logoutuser}
                            >
                              Log Out
                            </div>
                          </li>
                        </>
                      ) : (
                        <li class="nav-item">
                          <Link to={"/signin"} class="nav-link login_btn">
                            {" "}
                            Login
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header__info">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-4 col-lg-3">
                  <div className="header__info-left d-flex justify-content-center justify-content-sm-between align-items-center">
                    <div className="logo">
                      <a href="/">
                        <img src={Logo} alt="" />
                      </a>
                    </div>
                    <div className="header__hotline align-items-center d-none d-sm-flex  d-lg-none d-xl-flex">
                      <div className="header__hotline-icon">
                        <i className="fal fa-headset"></i>
                      </div>
                      <div className="header__hotline-info">
                        <span>Hotline Free:</span>
                        <h6>06-900-6789-00</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-9">
                  <div className="header__info-right">
                    <div className="header__search f-left d-none d-sm-block">
                      <form action="#">
                        <div className="header__search-box">
                          <input
                            type="text"
                            placeholder="Search For Products..."
                          />
                          <button type="submit">Search</button>
                        </div>
                        <div className="header__search-cat">
                          <select>
                            <option>All Categories</option>
                            <option>Best Seller Products</option>
                            <option>Top 10 Offers</option>
                            <option>New Arrivals</option>
                            <option>Phones & Tablets</option>
                            <option>Electronics & Digital</option>
                            <option>Fashion & Clothings</option>
                            <option>Jewelry & Watches</option>
                            <option>Health & Beauty</option>
                            <option>Sound & Speakers</option>
                            <option>TV & Audio</option>
                            <option>Computers</option>
                          </select>
                        </div>
                      </form>
                    </div>
                    <div className="cart__mini-wrapper d-none d-md-flex f-right p-relative">
                      <Link to={"/cart_products"} className="cart__toggle">
                        <span className="cart__total-item">
                          {cartProductDatas?.length}
                        </span>
                      </Link>
                      {/* <span className="cart__content">
                        <span className="cart__my">My Cart:</span>
                        <span className="cart__total-price">$ 255.00</span>
                      </span> */}
                      <div className="cart__mini">
                        <div className="cart__close">
                          <button type="button" className="cart__close-btn">
                            <i className="fal fa-times"></i>
                          </button>
                        </div>
                        <ul>
                          <li>
                            <div className="cart__title">
                              <h4>My Cart</h4>
                              <span>(1 Item in Cart)</span>
                            </div>
                          </li>
                          <li>
                            <div className="cart__item d-flex justify-content-between align-items-center">
                              <div className="cart__inner d-flex">
                                <div className="cart__thumb">
                                  <a href="product-details.html">
                                    <img src={cartMini} alt="" />
                                  </a>
                                </div>
                                <div className="cart__details">
                                  <h6>
                                    <a href="product-details.html">
                                      {" "}
                                      Samsung C49J89: £875, Debenhams Plus{" "}
                                    </a>
                                  </h6>
                                  <div className="cart__price">
                                    <span>$255.00</span>
                                  </div>
                                </div>
                              </div>
                              <div className="cart__del">
                                <a href="#">
                                  <i className="fal fa-trash-alt"></i>
                                </a>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="cart__sub d-flex justify-content-between align-items-center">
                              <h6>Car Subtotal</h6>
                              <span className="cart__sub-total">$255.00</span>
                            </div>
                          </li>
                          <li>
                            <a
                              href="checkout.html"
                              className="t-y-btn w-100 mb-10"
                            >
                              Proceed to checkout
                            </a>
                            <a
                              href="cart.html"
                              className="t-y-btn t-y-btn-border w-100 mb-10"
                            >
                              view add edit cart
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header__bottom">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-6">
                  <div className="header__bottom-left d-flex d-xl-block align-items-center">
                    <div className="side-menu d-xl-none mr-20">
                      <button
                        type="button"
                        className="side-menu-btn offcanvas-toggle-btn"
                      >
                        <i className="fas fa-bars"></i>
                      </button>
                    </div>
                    <div className="main-menu d-none d-md-block">
                      <nav>
                        <ul>
                          <li>
                            <Link class="nav-link" to={"/products"}>
                              Beauty Care
                            </Link>
                          </li>
                          {/* <li>
                            <a href="product.html">
                              Features <i className="far fa-angle-down"></i>
                            </a>
                            <ul className="submenu">
                              <li>
                                <a href="#">Product Type</a>
                              </li>
                              <li>
                                <a href="#">
                                  Product Features{" "}
                                  <i className="far fa-angle-down"></i>
                                </a>
                                <ul className="submenu">
                                  <li>
                                    <a href="#">Simple Product</a>
                                  </li>
                                  <li>
                                    <a href="#">Configurable Product</a>
                                  </li>
                                  <li>
                                    <a href="#">Group Product</a>
                                  </li>
                                  <li>
                                    <a href="#">Downloadable Product</a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a href="#">Shop By Brand</a>
                              </li>
                            </ul>
                          </li> */}
                          {/* <li>
                            <a href="blog.html">
                              Blog <i className="far fa-angle-down"></i>
                            </a>
                            <ul className="submenu">
                              <li>
                                <a href="#">Blog</a>
                              </li>
                              <li>
                                <a href="#">Blog Details</a>
                              </li>
                            </ul>
                          </li> */}
                          <li>
                            <Link class="nav-link" to={"/products"}>
                              Mom & Baby Care
                            </Link>
                          </li>
                          <li>
                            <Link to={"/products"} class="nav-link">
                              Daily Essentials
                            </Link>
                          </li>
                          <li>
                            <Link class="nav-link" to={"/products"}>
                              Quality of Life
                            </Link>
                          </li>
                          {/* <li>
                            <Link to={"/products"} class="nav-link">
                              Products
                            </Link>
                          </li> */}
                          <span className="special">Special Offers</span>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-3 col-lg-3  col-sm-6  col-6 d-md-none d-lg-block">
                  <div className="header__bottom-right d-flex justify-content-end">
                    <div className="header__currency">
                      <select>
                        <option>SAR</option>
                        <option>USD</option>
                      </select>
                    </div>
                    <div className="header__lang d-md-none d-lg-block">
                      <select>
                        <option>English</option>
                        <option>Arabic</option>
                      </select>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
