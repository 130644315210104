import React, { useRef, useState, useEffect, useCallback } from 'react'
import Header from '../Layout/header';
import Footer from '../Layout/footer';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import { productSelector, productdataDetails, relatedProducts } from "../../store/reducer/ProductReducer";
import { CartSelector, cartProductList, removecartProduct, addcartProduct, clearLoadings } from "../../store/reducer/CartReducer";
import debounce from "lodash/debounce";
import _ from "lodash";
import { API_BASE } from "../../services/config";
import Slider from "react-slick";
import { API_STATUS } from "../../utils/Constant";
import { toast } from "react-toastify";


import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const ProductDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, category_id } = useParams();
    const [quantity_count, setQuantity] = useState(1);
    const rangeRef = useRef(0);
    const rangeVRef = useRef(0);

    const { productIdDatas, relatedProductDatas, productdetailsLoading } = useSelector(productSelector);
    const { cartProductDatas, cartProducttotal, cartLoading, removecartLoading, addcartLoading, errorMessage } = useSelector(CartSelector);
    useEffect(() => {
        dispatch(productdataDetails({ id }));
        let getData = {};
        getData.category_id = category_id;
        getData.id = id;

        dispatch(relatedProducts({ getData }));
    }, [id]);

    useEffect(() => {
        console.log(productIdDatas, "productIdDatas");
    }, [productIdDatas]);

    useEffect(() => {
        console.log(relatedProductDatas, "relatedProductDatas");
    }, [relatedProductDatas]);


    const addcartProducts = (productData,type) => {
        if (localStorage.getItem('isAuthenticated')) {
            let adddata = []
            let datas = {};
            datas.product_id = productData.id;
            datas.quantity = ((type == 1) ? quantity_count.toString() : "1" );
            // datas.quantity = quantity_count.toString();
            datas.category_id = productData.catgory_id;
            datas.reg_price = productData.reg_price;
            datas.sale_price = productData.sale_price;
            adddata.push(datas)
            dispatch(addcartProduct({ payload: adddata }));
        } else {
            navigate("/signin");
            toast.warning("Signin to continue...")
        }
    }
    /**  Slider Settings */
    const settings = {
        // normal options...
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        arrows: true,

        // the magic
        responsive: [{
            breakpoint: 1500,
            settings: {
                slidesToShow: 4,
                infinite: true
            }

        }, {

            breakpoint: 1201,
            settings: {
                slidesToShow: 2,
                dots: true
            }

        }, {

            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                dots: true
            }

        }, {

            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                dots: true
            }

        }, {

            breakpoint: 300,
            settings: {
                slidesToShow: 1,
                dots: true
            },

        }]
    };
    /** Product Images */
    const images = [
        {
            original: "https://picsum.photos/id/1018/1000/600/",
            thumbnail: "https://picsum.photos/id/1018/250/150/",
        },
        {
            original: "https://picsum.photos/id/1015/1000/600/",
            thumbnail: "https://picsum.photos/id/1015/250/150/",
        },
        {
            original: "https://picsum.photos/id/1019/1000/600/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
        }
    ];

    const decreseQty = () => {
        let QtyCount = quantity_count - 1;
        (QtyCount > 0) ? setQuantity(QtyCount) : setQuantity(1);
        console.log(QtyCount, "TOASTS");
    }

    const increseQty = () => {
        let QtyCount = quantity_count + 1;
        setQuantity(QtyCount);
        console.log(QtyCount, "TOASTS");
    }
    useEffect(() => {
        if (addcartLoading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Product added in the Cart Successfully!");
            let page = 0;
            let limit = 0;
            let query = "";
            dispatch(cartProductList({ page, limit, query }));
            dispatch(clearLoadings());
        }
        if (errorMessage?.message) toast.error(errorMessage?.message);
        else if (errorMessage?.errors) {
            let data = "";
            console.log(errorMessage, "errorMessage");
            errorMessage?.errors.map((err) => {
                data += err.message + " ";
            });
            toast.error(data);
            // dispatch(clearLoadings());
        }
    }, [addcartLoading, errorMessage]);


    return (
        <>
            <Header />

            {/* <!-- SECTION START  --> */}

            <section class="pt-80">

                {/* <!--  PRODUCT DETAILS PART START  --> */}
                <div class="product_details">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                <div class="product_img d-flex">
                                    {/* <ImageGallery items={images} /> */}

                                    <div class="small_img" >
                                        <img src={API_BASE + productIdDatas?.image} class="w-100 img-fluid" alt="" />
                                        <img src={API_BASE + productIdDatas?.image} class="w-100 img-fluid" alt="" />
                                        <img src={API_BASE + productIdDatas?.image} class="w-100 img-fluid" alt="" />
                                    </div>
                                    <div class="big_img">

                                        <img src={API_BASE + productIdDatas?.image} class="w-100 img-fluid"  alt=""   />
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                <div class="producudetails_content">
                                    <h3>{productIdDatas?.name}</h3>
                                    <strong>SAR {productIdDatas?.sale_price} </strong>
                                    <strong><del>SAR {productIdDatas?.reg_price} </del></strong>
                                    <p>{productIdDatas?.featured}</p>
                                    <div class="add_to_cart d-flex">
                                        <div class="number-input">
                                            <button onClick={decreseQty} ></button>
                                            <input class="quantity" min="1" name="quantity" value={quantity_count} type="number" />
                                            <button onClick={increseQty} class="plus"></button>
                                        </div>
                                        <div class="grams">
                                        </div>
                                        <button class="btn custom-btn btn border-transparent ml-5" onClick={() => addcartProducts(productIdDatas,1)}>Add Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- PRODUCT DETAILS PART END --> */}

                {/* <!-- PRODUCT TABBER START --> */}
                <div class="product_tabber">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <ul class="nav nav-tabs nav_custom" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link nav-link-custom active" id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">DESCRIPTION</a>
                                    </li>
                                    {/* <!-- <li class="nav-item" role="presentation">
                                        <a class="nav-link nav-link-custom" id="information-tab" data-toggle="tab" href="#information" role="tab" aria-controls="information" aria-selected="false">INFORMATION</a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link nav-link-custom" id="review-tab" data-toggle="tab" href="#review" role="tab" aria-controls="review" aria-selected="false">REVIEW</a>
                                    </li> --> */}
                                </ul>

                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                                        {productIdDatas?.description}
                                    </div>

                                    {/* <!-- <div class="tab-pane fade" id="information" role="tabpanel" aria-labelledby="information-tab">

                                    </div>
                                    <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">

                                    </div> --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- PRODUCT TABBER END -->

                <!-- BEST SELLER PART START --> */}
                <div class="full-bestSeller">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 text-left">
                                <h3 class="section-title">Related Product</h3>
                            </div>
                        </div>

                        {/* <div class="row mt-2"> */}
                        <Slider {...settings} className="product-slider" >
                            {relatedProductDatas &&
                                relatedProductDatas.map((row, index) => {
                                    return (
                                        <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12 mb-12">
                                            <div class="product">

                                                <div class="product-img">
                                                    <img class="w-100" src={API_BASE + row?.image} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-details">
                                                        <h3 class="product-name">
                                                            <Link to={`/product_details/${row?.id}/${row?.catgory_id}`}> {row?.name}</Link>
                                                        </h3>
                                                        <span class="product-prev-price">SAR {row?.reg_price}</span>
                                                        <span class="product-price">SAR {row?.sale_price}</span>
                                                    </div>
                                                    <div class="buttons">
                                                        {(row?.trash == 1) ? <span class="sold-out-tag position-top-right"> "Sold out" </span> : <></>}
                                                        <button class="btn custom-btn position-bottom-right"onClick={() => addcartProducts(row,2)}> Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </Slider>

                        {/* </div> */}

                    </div>

                </div>

                {/* <!-- BEST SELLER PART END  --> */}

            </section>

            {/* <!-- SECTION END --> */}
            <Footer />
        </>
    )
};

export default ProductDetails