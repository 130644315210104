import { BrowserRouter, Routes, Route } from "react-router-dom";
// import "../src/assets/css/bootstrap.min.css"
// import "../src/assets/css/icofont.min.css"
// import "../src/assets/css/slick-theme.css"
// import "../src/assets/css/slick.css"
import PrivateRoute from "./privateRoute";
import Index from "./components/Home/Index";
import Product from "./components/Products/Products";
import ProductDetails from "./components/Products/ProductDetails";
import Login from "./components/Login/Login";
import TermsandCondition from "./components/TermsandCondition";
import PrivacyPolicy from "./components/PrivacyPolicy";
import About from "./components/About";
import Contact from "./components/Contact";
import CartProducts from "./components/Cart/CartProducts";
import MyOrders from "./components/MyOrders";
import MyAccount from "./components/MyAccount";
import ReturnProduct from "./components/ReturnProduct";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import "../src/assets/css/style.css"
// import "../src/assets/css/responsive.css"

import "../src/assets/new-styles/css/preloader.css";
import "../src/assets/new-styles/css/bootstrap.min.css";
import "../src/assets/new-styles/css/slick.css";
import "../src/assets/new-styles/css/backToTop.css";
import "../src/assets/new-styles/css/meanmenu.css";
import "nice-select2/dist/css/nice-select2.css";
import "../src/assets/new-styles/css/animate.min.css";
import "../src/assets/new-styles/css/jquery.fancybox.min.css";
import "../src/assets/new-styles/css/fontAwesome5Pro.css";
import "../src/assets/new-styles/css/ui-range-slider.css";
import "../src/assets/new-styles/css/default.css";
import "../src/assets/new-styles/css/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ShippingPolicy from "./components/ShippingPolicy";

function App() {
    return (
        <>
            <ToastContainer />
            {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}`
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
            {/* Same as */}
            {/* <ToastContainer /> */}
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Index />} />
                    <Route path="/signin" element={<Login />} />
                    <Route path="/products" element={<Product />} />
                    <Route path="/product_details/:id/:category_id" element={<ProductDetails />} />
                    <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                    <Route path="/about_us" element={<About />} />
                    <Route path="/contact_us" element={<Contact />} />
                    <Route path="/terms_and_conditions" element={<TermsandCondition />} />
                    <Route path="/my_orders" element={<MyOrders />} />
                    <Route path="/my_account" element={<MyAccount />} />
                    <Route path="/return_policy" element={<ReturnProduct />} />
                    <Route path="/shipping_policy" element={<ShippingPolicy />} />
                    < Route path="/" element={<PrivateRoute />} >
                        {/* { */}
                        <Route path="/cart_products" element={<CartProducts />} />
                        <Route path="/*" element={<>Page Not Found</>} />
                        {/* } */}
                    </Route>
                </Routes>
            </BrowserRouter >
        </>
    );
}

export default App;
