import React, { useEffect, useRef, useState } from "react";
import Header from "../Layout/header";
import Slider from "react-slick";
import Footer from "../Layout/footer";
import Banner from "./Banner";
import BestSeller from "./BestSeller";
import Features from "./Features";
import slider1 from "../../assets/img/slider/slider-01.jpg";
import slider2 from "../../assets/img/slider/slider-02.jpg";
import promo1 from "../../assets/img/promotions/1.webp";
import promo2 from "../../assets/img/promotions/2.webp";
import promo3 from "../../assets/img/promotions/3.webp";
import promo4 from "../../assets/img/promotions/4.webp";
import promo5 from "../../assets/img/promotions/5.webp";
import promo6 from "../../assets/img/promotions/6.webp";
import product1 from "../../assets/img/products/1.jpg";
import brands1 from "../../assets/img/brands/img_1.png";
import brands2 from "../../assets/img/brands/img_2.png";
import brands3 from "../../assets/img/brands/img_3.png";
import brands4 from "../../assets/img/brands/img_4.png";
import brands5 from "../../assets/img/brands/img_5.png";
import brands6 from "../../assets/img/brands/img_6.png";
import baby from "../../assets/img/slider/baby.jpg";
import beauty from "../../assets/img/slider/beauty.png";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  offerList,
  productSelector,
  productList,
} from "../../store/reducer/ProductReducer";
import {
  CartSelector,
  cartProductList,
  removecartProduct,
  addcartProduct,
  clearLoadings,
} from "../../store/reducer/CartReducer";
import { API_BASE } from "../../services/config";
import { toast } from "react-toastify";
import { API_STATUS } from "../../utils/Constant";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const carouselRef = useRef(null);

  const {
    categoryDatas,
    productDatas,
    productFilterDatas,
    productLoading,
    catgLoading,
  } = useSelector(productSelector);
  const {
    cartProductDatas,
    cartProducttotal,
    cartLoading,
    removecartLoading,
    addcartLoading,
    errorMessage,
  } = useSelector(CartSelector);
  const options = {
    loop: true,
    responsiveClass: true,
    dots: false,
    autoplay: true,
    responsive: {
      0: { items: 1, dots: true },
      768: { items: 2 },
    },
  };

  useEffect(() => {
    let page = 0;
    let limit = 4;
    let query = "";

    // dispatch(categoryList({ page, limit, query }));
    dispatch(productList({ page, limit, query }));
    // dispatch(settingData({}))
  }, []);

  const events = {
    onDragged: function (event) {
      console.log("====onDragged==>>", event);
    },
    onChanged: function (event) {
      console.log("====onChanged==>>", event);
    },
  };
  const addcartProducts = (row) => {
    if (localStorage.getItem("isAuthenticated")) {
      const data = [];
      let addData = {};
      addData.product_id = row.id;
      addData.quantity = "1";
      addData.category_id = row.catgory_id;
      addData.reg_price = row.reg_price;
      addData.sale_price = row.sale_price;
      data.push(addData);
      dispatch(addcartProduct({ payload: data }));
    } else {
      navigate("/signin");
      toast.warning("Signin to continue...");
    }
  };
  useEffect(() => {
      if (addcartLoading === API_STATUS.FULFILLED) {
          toast.dismiss();
          toast.success("Product added in the Cart Successfully!");
          let page = 0;
          let limit = 0;
          let query = "";
          dispatch(cartProductList({ page, limit, query }));
          dispatch(clearLoadings());
      }
      if (errorMessage?.message) toast.error(errorMessage?.message);
      else if (errorMessage?.errors) {
          let data = "";
          console.log(errorMessage, "errorMessage");
          errorMessage?.errors.map((err) => {
              data += err.message + " ";
          });
          toast.error(data);
          // dispatch(clearLoadings());
      }
  }, [addcartLoading, errorMessage]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Header />
      <section className="slider__area grey-bg">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 custom-col-6 col-lg-6 padding">
              <Slider {...settings} className="slide">
                <div>
                  <img src={slider1} alt="slider" />
                </div>

                <div>
                  <img src={slider2} alt="slider" />
                </div>
              </Slider>
            </div>
            <div className="col-xl-6 custom-col-6 col-lg-6 static padding">
              <div>
                <img src={beauty}/>
              </div>
              <div>
                <img src={baby}/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features__area grey-bg-2 pt-30 pb-20 pl-10 pr-10">
        <div className="container">
          <div className="row row-cols-xxl-5 row-cols-xl-5 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 gx-0">
            <div className="col">
              <div className="features__item d-flex white-bg">
                <div className="features__icon mr-15">
                  <i className="fal fa-rocket-launch"></i>
                </div>
                <div className="features__content">
                  <h6>Free Shipping</h6>
                  <p>Free Shipping On All Order</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="features__item d-flex white-bg">
                <div className="features__icon mr-15">
                  <i className="fal fa-sync"></i>
                </div>
                <div className="features__content">
                  <h6>Money Guarantee</h6>
                  <p>30 Day Money Back Guarantee</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="features__item d-flex white-bg">
                <div className="features__icon mr-15">
                  <i className="fal fa-user-headset"></i>
                </div>
                <div className="features__content">
                  <h6>Online Support 24/7</h6>
                  <p>Technical Support Stand By</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="features__item d-flex white-bg">
                <div className="features__icon mr-15">
                  <i className="fal fa-thumbs-up"></i>
                </div>
                <div className="features__content">
                  <h6>Secure Payment</h6>
                  <p>All Payment Method are accepted</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="features__item features__item-last d-flex white-bg">
                <div className="features__icon mr-15">
                  <i className="fal fa-badge-dollar"></i>
                </div>
                <div className="features__content">
                  <h6>Member Discount</h6>
                  <p>Upto 40% Discount All Products</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner__area pt-20 grey-bg-2">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="banner__item w-img mb-30">
                <a href="product-details.html">
                  <img src={promo1} alt="" />
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="banner__item mb-30 w-img">
                <a href="product-details.html">
                  <img src={promo2} alt="" />
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="banner__item mb-30 w-img">
                <a href="product-details.html">
                  <img src={promo3} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="deal__area pb-45 pt-25 grey-bg ">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="section__head d-md-flex justify-content-between mb-40">
                <div className="section__title">
                  <h3>
                    Deals<span>Of The Day</span>
                  </h3>
                </div>
              </div>

              <div className="product__deal-3 t-nav">
                <div className="product__deal-item">
                  <div className="product__item white-bg product__sale mb-30">
                    <div className="row">
                      <div className="col-xl-6  col-lg-6 col-md-6 col-sm-6">
                        <div className="product__thumb product__thumb-big p-relative">
                          <Link
                            to={
                              "/product_details/" +
                              productDatas[3]?.id +
                              "/" +
                              productDatas[3]?.catgory_id
                            }
                            className="w-img"
                          >
                            <img
                              src={API_BASE + productDatas[3]?.image}
                              alt="product"
                            />
                            <img
                              className="second-img"
                              src={API_BASE + productDatas[3]?.image}
                              alt="product"
                            />
                          </Link>
                          <div className="product__offer">
                            <span className="discount">-34%</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="product__content product__content-2">
                          <h6 className="product-name product__deal-name">
                            <Link
                              to={
                                "/product_details/" +
                                productDatas[3]?.id +
                                "/" +
                                productDatas[3]?.catgory_id
                              }
                              className="product-item-link"
                            >
                              {" "}
                              {productDatas[3]?.name}
                            </Link>
                          </h6>
                          <div className="rating rating-2">
                            <ul>
                              <li>
                                <a href="#">
                                  <i className="far fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="far fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="far fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="far fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="far fa-star"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <span className="new mb-5">
                            {productDatas[3]?.sale_price}
                          </span>
                          <span className="price-old mb-5">
                            {" "}
                            <del>{productDatas[3]?.reg_price}</del>{" "}
                          </span>
                          <p className="mt-10 truncate">
                            {productDatas[3]?.description}
                          </p>
                          <div className="product__countdown">
                            <h4>Hurry Up! Offer ends in:</h4>
                            <div className="countdown-wrapper">
                              <div
                                data-countdown
                                data-date="Dec 02 2022 20:20:22"
                              >
                                <ul>
                                  <li>
                                    <span data-days>0</span>
                                    <p>Days</p>
                                  </li>
                                  <li>
                                    <span data-hours>0</span>
                                    <p>Hours</p>
                                  </li>
                                  <li>
                                    <span data-minutes>0</span>
                                    <p>mins</p>
                                  </li>
                                  <li>
                                    <span data-seconds>0</span>
                                    <p>secs</p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="section__head d-md-flex justify-content-between mb-40">
                <div className="section__title">
                  <h3>
                    Electronic & Digital<span>Products</span>
                  </h3>
                </div>
              </div>
              <div className="product__electronic-3 t-nav">
                {productDatas?.length > 0
                  ? productDatas.map((row, index) => {
                      if (index <= 2) {
                        return (
                          <div className="product__electronic-item">
                            <div className="product__item white-bg">
                              <div className="product__thumb p-relative">
                                <Link
                                  to={
                                    "/product_details/" +
                                    row.id +
                                    "/" +
                                    row.catgory_id
                                  }
                                  className="w-img"
                                >
                                  <img
                                    src={API_BASE + row?.image}
                                    alt="product"
                                  />
                                  <img
                                    className="second-img"
                                    src={API_BASE + row?.image}
                                    alt="product"
                                  />
                                </Link>
                                <div className="product__offer">
                                  <span className="discount">-34%</span>
                                </div>
                                <div className="product__action p-absolute">
                                  <ul>
                                    {/* <li>
                                    <a href="#" title="Add to Wishlist">
                                      <i className="fal fa-heart"></i>
                                    </a>
                                  </li> */}
                                    {/* <li>
                                    <a
                                      href="#"
                                      title="Quick View"
                                      data-bs-toggle="modal"
                                      data-bs-target="#productModalId"
                                    >
                                      <i className="fal fa-search"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" title="Compare">
                                      <i className="far fa-sliders-h"></i>
                                    </a>
                                  </li> */}
                                  </ul>
                                </div>
                              </div>
                              <div className="product__content text-center">
                                <h6 className="product-name">
                                  <Link
                                    to={
                                      "/product_details/" +
                                      row.id +
                                      "/" +
                                      row.catgory_id
                                    }
                                    className="product-item-link"
                                  >
                                    {" "}
                                    High Quality Glass Ultra-Thin Kitchen Scale
                                  </Link>
                                </h6>
                                <div className="rating">
                                  <ul>
                                    <li>
                                      <a href="#">
                                        <i className="far fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="far fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="far fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="far fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="far fa-star"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <span className="price">
                                  SAR {row?.sale_price}
                                </span>
                              </div>
                              <div className="product__add-btn">
                                <button
                                  type="button"
                                  onClick={() => addcartProducts(row)}
                                >
                                  Add to Cart
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  : ""}
                {/* <div className="product__electronic-item">
                  <div className="product__item white-bg">
                    <div className="product__thumb p-relative">
                      <a href="product-details.html" className="w-img">
                        <img src={product1} alt="product" />
                        <img
                          className="second-img"
                          src={product1}
                          alt="product"
                        />
                      </a>
                      <div className="product__offer">
                        <span className="discount">-34%</span>
                      </div>
                      <div className="product__action p-absolute">
                        <ul>
                          <li>
                            <a href="#" title="Add to Wishlist">
                              <i className="fal fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              title="Quick View"
                              data-bs-toggle="modal"
                              data-bs-target="#productModalId"
                            >
                              <i className="fal fa-search"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Compare">
                              <i className="far fa-sliders-h"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="product__content text-center">
                      <h6 className="product-name">
                        <a
                          className="product-item-link"
                          href="product-details.html"
                        >
                          {" "}
                          LG 27UD58: £347.21, <br />
                          Ebuyer.com{" "}
                        </a>
                      </h6>
                      <div className="rating">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="far fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="far fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="far fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="far fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="far fa-star"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <span className="price">$560.00</span>
                    </div>
                    <div className="product__add-btn">
                      <button type="button">Add to Cart</button>
                    </div>
                  </div>
                </div>
                <div className="product__electronic-item">
                  <div className="product__item white-bg">
                    <div className="product__thumb p-relative">
                      <a href="product-details.html" className="w-img">
                        <img src={product1} alt="product" />
                        <img
                          className="second-img"
                          src={product1}
                          alt="product"
                        />
                      </a>
                      <div className="product__offer">
                        <span className="discount">-34%</span>
                      </div>
                      <div className="product__action p-absolute">
                        <ul>
                          <li>
                            <a href="#" title="Add to Wishlist">
                              <i className="fal fa-heart"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              title="Quick View"
                              data-bs-toggle="modal"
                              data-bs-target="#productModalId"
                            >
                              <i className="fal fa-search"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Compare">
                              <i className="far fa-sliders-h"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="product__content text-center">
                      <h6 className="product-name">
                        <a
                          className="product-item-link"
                          href="product-details.html"
                        >
                          {" "}
                          Samsung C49J89: £875, Debenhams Plus{" "}
                        </a>
                      </h6>
                      <div className="rating">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="far fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="far fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="far fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="far fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="far fa-star"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <span className="price">$450.00</span>
                    </div>
                    <div className="product__add-btn">
                      <button type="button">Add to Cart</button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner__area pt-20 pb-30 grey-bg-2">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="banner__item mb-30 w-img">
                <a href="#">
                  <img src={promo4} alt="" />
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="banner__item mb-30 w-img">
                <a href="#">
                  <img src={promo5} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="deal__area pb-45 pt-25 grey-bg ">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="section__head d-md-flex justify-content-between mb-40">
                <div className="section__title">
                  <h3>
                    Deals<span>Of The Day</span>
                  </h3>
                </div>
              </div>

              <div className="product__deal-3 t-nav">
                <div className="product__deal-item">
                  <div className="product__item white-bg product__sale mb-30">
                    <div className="row">
                      <div className="col-xl-6  col-lg-6 col-md-6 col-sm-6">
                        <div className="product__thumb product__thumb-big p-relative">
                          <Link
                            to={
                              "/product_details/" +
                              productDatas[3]?.id +
                              "/" +
                              productDatas[3]?.catgory_id
                            }
                            className="w-img"
                          >
                            <img
                              src={API_BASE + productDatas[3]?.image}
                              alt="product"
                            />
                            <img
                              className="second-img"
                              src={API_BASE + productDatas[3]?.image}
                              alt="product"
                            />
                          </Link>
                          <div className="product__offer">
                            <span className="discount">-34%</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="product__content product__content-2">
                          <h6 className="product-name product__deal-name">
                            <Link
                              to={
                                "/product_details/" +
                                productDatas[3]?.id +
                                "/" +
                                productDatas[3]?.catgory_id
                              }
                              className="product-item-link"
                            >
                              {" "}
                              {productDatas[3]?.name}
                            </Link>
                          </h6>
                          <div className="rating rating-2">
                            <ul>
                              <li>
                                <a href="#">
                                  <i className="far fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="far fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="far fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="far fa-star"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="far fa-star"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <span className="new mb-5">
                            {productDatas[3]?.sale_price}
                          </span>
                          <span className="price-old mb-5">
                            {" "}
                            <del>{productDatas[3]?.reg_price}</del>{" "}
                          </span>
                          <p className="mt-10 truncate">
                            {productDatas[3]?.description}
                          </p>
                          <div className="product__countdown">
                            <h4>Hurry Up! Offer ends in:</h4>
                            <div className="countdown-wrapper">
                              <div
                                data-countdown
                                data-date="Dec 02 2022 20:20:22"
                              >
                                <ul>
                                  <li>
                                    <span data-days>0</span>
                                    <p>Days</p>
                                  </li>
                                  <li>
                                    <span data-hours>0</span>
                                    <p>Hours</p>
                                  </li>
                                  <li>
                                    <span data-minutes>0</span>
                                    <p>mins</p>
                                  </li>
                                  <li>
                                    <span data-seconds>0</span>
                                    <p>secs</p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="section__head d-md-flex justify-content-between mb-40">
                <div className="section__title">
                  <h3>
                    Electronic & Digital<span>Products</span>
                  </h3>
                </div>
              </div>
              <div className="product__electronic-3 t-nav">
                {productDatas?.length > 0
                  ? productDatas.map((row, index) => {
                      if (index <= 2) {
                        return (
                          <div className="product__electronic-item">
                            <div className="product__item white-bg">
                              <div className="product__thumb p-relative">
                                <Link
                                  to={
                                    "/product_details/" +
                                    row.id +
                                    "/" +
                                    row.catgory_id
                                  }
                                  className="w-img"
                                >
                                  <img
                                    src={API_BASE + row?.image}
                                    alt="product"
                                  />
                                  <img
                                    className="second-img"
                                    src={API_BASE + row?.image}
                                    alt="product"
                                  />
                                </Link>
                                <div className="product__offer">
                                  <span className="discount">-34%</span>
                                </div>
                                <div className="product__action p-absolute">
                                  <ul>
                                    {/* <li>
                              <a href="#" title="Add to Wishlist">
                                <i className="fal fa-heart"></i>
                              </a>
                            </li> */}
                                    {/* <li>
                              <a
                                href="#"
                                title="Quick View"
                                data-bs-toggle="modal"
                                data-bs-target="#productModalId"
                              >
                                <i className="fal fa-search"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" title="Compare">
                                <i className="far fa-sliders-h"></i>
                              </a>
                            </li> */}
                                  </ul>
                                </div>
                              </div>
                              <div className="product__content text-center">
                                <h6 className="product-name">
                                  <Link
                                    to={
                                      "/product_details/" +
                                      row.id +
                                      "/" +
                                      row.catgory_id
                                    }
                                    className="product-item-link"
                                  >
                                    {" "}
                                    High Quality Glass Ultra-Thin Kitchen Scale
                                  </Link>
                                </h6>
                                <div className="rating">
                                  <ul>
                                    <li>
                                      <a href="#">
                                        <i className="far fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="far fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="far fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="far fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="far fa-star"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <span className="price">
                                  SAR {row?.sale_price}
                                </span>
                              </div>
                              <div className="product__add-btn">
                                <button
                                  type="button"
                                  onClick={() => addcartProducts(row)}
                                >
                                  Add to Cart
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  : ""}
                {/* <div className="product__electronic-item">
            <div className="product__item white-bg">
              <div className="product__thumb p-relative">
                <a href="product-details.html" className="w-img">
                  <img src={product1} alt="product" />
                  <img
                    className="second-img"
                    src={product1}
                    alt="product"
                  />
                </a>
                <div className="product__offer">
                  <span className="discount">-34%</span>
                </div>
                <div className="product__action p-absolute">
                  <ul>
                    <li>
                      <a href="#" title="Add to Wishlist">
                        <i className="fal fa-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        title="Quick View"
                        data-bs-toggle="modal"
                        data-bs-target="#productModalId"
                      >
                        <i className="fal fa-search"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Compare">
                        <i className="far fa-sliders-h"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="product__content text-center">
                <h6 className="product-name">
                  <a
                    className="product-item-link"
                    href="product-details.html"
                  >
                    {" "}
                    LG 27UD58: £347.21, <br />
                    Ebuyer.com{" "}
                  </a>
                </h6>
                <div className="rating">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="far fa-star"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="far fa-star"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="far fa-star"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="far fa-star"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="far fa-star"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <span className="price">$560.00</span>
              </div>
              <div className="product__add-btn">
                <button type="button">Add to Cart</button>
              </div>
            </div>
          </div>
          <div className="product__electronic-item">
            <div className="product__item white-bg">
              <div className="product__thumb p-relative">
                <a href="product-details.html" className="w-img">
                  <img src={product1} alt="product" />
                  <img
                    className="second-img"
                    src={product1}
                    alt="product"
                  />
                </a>
                <div className="product__offer">
                  <span className="discount">-34%</span>
                </div>
                <div className="product__action p-absolute">
                  <ul>
                    <li>
                      <a href="#" title="Add to Wishlist">
                        <i className="fal fa-heart"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        title="Quick View"
                        data-bs-toggle="modal"
                        data-bs-target="#productModalId"
                      >
                        <i className="fal fa-search"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" title="Compare">
                        <i className="far fa-sliders-h"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="product__content text-center">
                <h6 className="product-name">
                  <a
                    className="product-item-link"
                    href="product-details.html"
                  >
                    {" "}
                    Samsung C49J89: £875, Debenhams Plus{" "}
                  </a>
                </h6>
                <div className="rating">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="far fa-star"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="far fa-star"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="far fa-star"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="far fa-star"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="far fa-star"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <span className="price">$450.00</span>
              </div>
              <div className="product__add-btn">
                <button type="button">Add to Cart</button>
              </div>
            </div>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner__area pt-20 pb-30 grey-bg-2">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="banner__item w-img">
                <a href="">
                  <img src={promo6} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner__area pt-20 pb-60 grey-bg-2">
        <div className="container">
          <div className="row">
            <div className="brands d-flex">
              <div className="brands_list">
                <img src={brands1} alt="" />
              </div>
              <div className="brands">
                <img src={brands2} alt="" />
              </div>
              <div className="brands_list">
                <img src={brands3} alt="" />
              </div>
              <div className="brands">
                <img src={brands4} alt="" />
              </div>
              <div className="brands_list">
                <img src={brands5} alt="" />
              </div>
              <div className="brands_list">
                <img src={brands6} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Index;
