import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { bannerList, homeSelector } from "../../store/reducer/HomeReducer";
import { API_BASE } from "../../services/config";
import { Link } from "react-router-dom";
const Banner = () => {
  const dispatch = useDispatch();
  const { bannerdata } = useSelector(homeSelector);
  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    arrows: false,
    dots: true,
  };
  const [data, setDatas] = useState([]);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState("");
  const [limit, setLimit] = useState("");

  useEffect(() => {
    dispatch(bannerList({ page, limit, query }));
  }, []);

  useEffect(() => {
    const element = bannerdata[0]?.content?.split(",");
    const q_ele = bannerdata[0]?.quote?.split(",");
    const des_ele = bannerdata[0]?.description?.split(",");
    for (let index = 0; index < element?.length; index++) {
      let data = {};
      data.content = element[index];
      data.quote = q_ele[index];
      data.description = des_ele[index];
      setDatas((prev) => [...prev, data]);
    }
  }, [bannerdata]);

  return (
    <>
      <div
        class="full-banner"
        style={{
          background: ` url(${API_BASE}${bannerdata && bannerdata[0]?.image})`,
        }}
      >
        <div class="container">
          {/* <div class="row banner-slider"> */}
            <Slider {...settings}>
              {data?.map((values, i) => {
                let spl = values.quote.split(" ");
                if (spl.length > 1) {
                  spl = [spl[0], spl.slice(1).join(" ")];
                }
                // if (i === 0)
                  return (
                    <>
                      <div class="col-md-6">
                        <div class="banner-content">
                          <h1>
                            {spl[0]} <span>{spl[1]}</span>
                          </h1>
                          <h3>{values?.content}</h3>
                          <p>{values?.description}</p>
                          <Link to={"/products"} class="btn">
                            All Products
                          </Link>
                        </div>
                      </div>
                    </>
                  );
              })}
            </Slider>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default Banner;
