import React, { useState } from 'react'
import Header from './Layout/header'
import Footer from './Layout/footer'
import aboutbg from '../assets/img/inner-banner/about-bg.jpg'
import abt_1 from '../assets/img/about/about-1.jpg'
import abt_2 from '../assets/img/about/about-1.jpg'
import abt_3 from '../assets/img/about/about-1.jpg'


const About = () => {
    const [check, setCheck] = useState({
        1: true,
        2: true,
        3: true,
        4: true,
        5: true
    });
    const handleCheck = id => {
        let f_data = {}
        const chk_data = Object.keys(check);
        for (let index = 0; index < chk_data.length; index++) {
            const element = chk_data[index];
            console.log(element, id, 'chk dataa')
            if (id == element) {
                f_data[element] = !check[element]
            } else {
                f_data[element] = true
            }
            setCheck(f_data)
        }
    };
    return (
        <div>
            <Header />
            <div>
                <section className="about__banner-area">
                    <div className="container-fluid p-0">
                        <div className="row gx-0">
                            <div className="col-xxl-12">
                                <div className="about__banner w-img">
                                    <img src={aboutbg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about__area box-plr-75 p-relative about__mt--325">
                    <div className="container-fluid">
                        <div className="about__inner white-bg">
                            <div className="row">
                                <div className="col-xxl-12">
                                    <div className="about__wrapper">
                                        <h3>About Topico Magento</h3>
                                        <p className="about__text">Vivamus a lacinia sem. Integer in enim sapien. Aenean vitae hendrerit nisi, ut suscipit justo. Duis hendrerit sceleri sque dui. Donec ornare massa vitae neque lobortis rutrum.</p>

                                        <div className="about__quote">
                                            <blockquote>
                                                <p>Lorem ipsum <span>dolor sit amet</span>, consectetur adipiscing elit. Nunc hendrerit tellus et nisi ultra trices, eu feugiat sapien commodo. Praesent vitae ipsum et risus.</p>
                                            </blockquote>
                                        </div>
                                        <p className="about__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit tellus et nisi ultrices, eu feugiat sapien com modo. Praesent vitae ipsum et risus tempus tincidunt in tincidunt justo. Nunc consectetur non sapien id faucibus. Curabitur id nibh eu felis pellentesque pellentesque quis vel nulla. Vivamus a lacinia sem. Integer in enim sapien. Aenean vitae hendrerit nisi, ut suscipit justo. Duis hendrerit scelerisque dui. Donec ornare massa vitae neque lobor tis rutrum.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="why__area box-plr-75 pt-60 pb-40">
                <div className="container-fluid">
                    <div className="why__inner pl-90 pr-90">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="section__head mb-30">
                                    <div className="section__title">
                                        <h3>What We Do?</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                <div className="why__item mb-30">
                                    <div className="why__thumb w-img fix">
                                        <img src={abt_1} alt="" />
                                    </div>
                                    <div className="why__content">
                                        <h3>What Do We Do?</h3>
                                        <p>Suspendisse turpis ipsum, tempus in nulla eu, posuere pharetra nibh. In dignissim vitae lorem non mollis.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                <div className="why__item mb-30">
                                    <div className="why__thumb w-img fix">
                                        <img src={abt_2}alt="" />
                                    </div>
                                    <div className="why__content">
                                        <h3>Our Mission?</h3>
                                        <p>Suspendisse turpis ipsum, tempus in nulla eu, posuere pharetra nibh. In dignissim vitae lorem non mollis.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                <div className="why__item mb-30">
                                    <div className="why__thumb w-img fix">
                                        <img src={abt_3} alt="" />
                                    </div>
                                    <div className="why__content">
                                        <h3>History Of Us?</h3>
                                        <p>Suspendisse turpis ipsum, tempus in nulla eu, posuere pharetra nibh. In dignissim vitae lorem non mollis.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div >
            <Footer />
        </div >
    )
}

export default About