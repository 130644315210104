import React, { useEffect } from 'react'
import Header from './Layout/header'
import Footer from './Layout/footer'
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { contactUsValidationSchema } from '../utils/Validation'
import { useDispatch, useSelector } from 'react-redux';
import { ContactUs, clearMessages, homeSelector, settingData } from '../store/reducer/HomeReducer';
import { toast } from 'react-toastify';

const Contact = () => {
  const dispatch = useDispatch()
  const { successMessage } = useSelector(homeSelector);
  const { settingDatas } = useSelector(homeSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(contactUsValidationSchema),
  });
  const submitHanlder = formData => {
    console.log(formData)
    dispatch(ContactUs({ formData }))
  }

  useEffect(() => {
    if (successMessage !== "") {
      toast.success(successMessage)
      dispatch(clearMessages())
      reset()
    }
  }, [successMessage])

  useEffect(() => {
    dispatch(settingData({}))
  }, [])


  return (
    <div>
      <Header />
          <section className="contact__area">
            <div className="container-fluid p-0">
                <div className="row gx-0">
                    <div className="col-xxl-12">
                        <div className="contact__map">
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.7108195207793!2d46.7063029!3d24.6680803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f05f357a63455%3A0x736a709012fb63e5!2z2LTYsdmD2Kkg2YXYr9in2LEg2KfZhNin2YLYqti12KfYryDYp9mE2LfYqNmK2Kk!5e0!3m2!1sen!2sin!4v1724074247465!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
          </section>

          <section className="contact__area box-plr-90 pt-55 pb-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-5">
                            <div className="contact__info">
                                <h3 className="contact__title">Our Main Office.</h3>
                                <p className="contact__text">Whether you are a healthcare provider looking for reliable supplies or a distributor interested in partnering with us, we are here to help. Contact us today to learn more about our products and how we can support your healthcare needs.</p>

                                <div className="contact__hotline d-flex align-items-center mb-35">
                                    <div className="contact__hotline-icon mr-20">
                                        <i className="fal fa-headset"></i>
                                    </div>
                                    <div className="contact__hotline-info">
                                        <span>Hotline Free 24/7:</span>
                                        <h6><a href="tel:+966537664998">(+966) 537664998</a></h6>
                                    </div>
                                </div>
                                <div className="contact__address mb-20">
                                    <ul>
                                        <li>
                                            <p><span>Add:</span> سابقا، شارع الامير عبد العزيز بن مساعد بن جلوي الضباب, Riyadh 14352, Saudi Arabia</p>
                                        </li>
                                        <li>
                                            <p><span>Email:</span> info@madaraleqtsad.com</p>
                                        </li>
                                        <li>
                                            <p><span>Business Phone: </span> <a href="tel:+966537664998">(+966) 537664998</a></p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="contact__social">
                                    <span>Social Share:</span>
                                    <ul>
                                        <li>
                                            <a href="#"><i className="fal fa-basketball-ball"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fab fa-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fab fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="fab fa-facebook-f"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-8 col-xl-8 col-lg-7">
                            <div className="contact__form">
                                <h3 className="contact__title">Our Main Office.</h3>
                                <form action="#" onSubmit={handleSubmit(submitHanlder)}>
                                    <div className="row">
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                            <div className="contact__input">
                                                <span>Name</span>
                                                <input type="text" placeholder="Your Name..." {...register("name")} />
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                            <div className="contact__input">
                                                <span>Email</span>
                                                <input type="text" placeholder="Your Email..." {...register("email")} />
                                            </div>
                                        </div>
                                        <div className="col-xxl-12">
                                            <div className="contact__input">
                                                <span>Phone Number</span>
                                                <input type="number" placeholder="Your Number..." {...register("phone_number")} />
                                            </div>
                                        </div>
                                        <div className="col-xxl-12">
                                            <div className="contact__input">
                                                <span>What’s on your mind?</span>
                                                <textarea placeholder="Write Something..." style={{ height: "200px" }} {...register("message")}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-xxl-12">
                                            <div className="contact__btn">
                                                <button type="submit" className="t-y-btn">send message</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      <Footer />
    </div>
  )
}

export default Contact