import React, { useRef, useState, useEffect, useCallback } from 'react'
import Header from '../Layout/header';
import Footer from '../Layout/footer';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { productSelector, categoryList, productList, filterData } from "../../store/reducer/ProductReducer";
import { CartSelector, cartProductList, removecartProduct, addcartProduct,clearLoadings } from "../../store/reducer/CartReducer";
import debounce from "lodash/debounce";
import _ from "lodash";
import { API_BASE } from "../../services/config";
import { toast } from "react-toastify";
import { API_STATUS } from "../../utils/Constant";
import { homeSelector, settingData } from '../../store/reducer/HomeReducer';

const Products = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [range, setRange] = useState(3);
    const [showdata, setshowdata] = useState(0);
    const [fquery, setFQuery] = useState([]);
    const [prices, setPrices] = useState("800");
    const rangeRef = useRef(0)
    const rangeVRef = useRef(0)
    const { settingDatas } = useSelector(homeSelector);

    useEffect(() => {
        setPrices(settingDatas?.max_range ?? "800")
    }, [settingDatas])

    const { categoryDatas, productDatas, productFilterDatas, productLoading, catgLoading, } = useSelector(productSelector);
    const { cartProductDatas, cartProducttotal, cartLoading, removecartLoading, addcartLoading, errorMessage } = useSelector(CartSelector);
    useEffect(() => {
        let page = 0;
        let limit = 0;
        let query = "";

        dispatch(categoryList({ page, limit, query }));
        dispatch(productList({ page, limit, query }));
        dispatch(settingData({}))
    }, []);

    useEffect(() => {
        console.log(categoryDatas, "categoryDatas");
    }, [categoryDatas]);

    useEffect(() => {
        console.log(productDatas, "productDatas");
    }, [productDatas]);
    useEffect(() => {
        console.log(productFilterDatas, "productFilterDatas");
    }, [productFilterDatas]);

    const handleChange = (event) => {
        setPrices(event.target.value);
        const
            newValue = Number((event.target.value - rangeRef.current.min) * 100 / (rangeRef.current.max - rangeRef.current.min)),
            newPosition = 10 - (newValue * 0.2);
        rangeVRef.current.classList.remove('range-valueX')
        rangeVRef.current.innerHTML = `<span>${event.target.value}</span>`;
        rangeVRef.current.style.left = `calc(${newValue}% + (${newPosition}px))`;
        console.log((event.target.value), "ONPRICES");
        filterFun(fquery, event.target.value);
    }

    const handleOnSearch = event => {
        const { id } = event.target;
        const { checked } = event.target;
        let category_ids = fquery;
        if (checked) {
            category_ids.push(id)
        } else {
            category_ids = category_ids.filter((items) => { return items != id })
        }
        setFQuery(category_ids);
        filterFun(category_ids, prices);
    }

    const filterFun = (fquery, prices) => {
        let filterArray = {};
        filterArray.category_ids = fquery;
        filterArray.prices = prices;
        dispatch(filterData({ filterArray }));
        setshowdata(1);
    }


    const addcartProducts = (row) => {
        if (localStorage.getItem('isAuthenticated')) {
            const data = []
            let addData = {};
            addData.product_id = row.id;
            addData.quantity = "1";
            addData.category_id = row.catgory_id;
            addData.reg_price = row.reg_price;
            addData.sale_price = row.sale_price;
            data.push(addData)
            dispatch(addcartProduct({ payload: data }));
        } else {
            navigate("/signin");
            toast.warning("Signin to continue...")
        }
    }
    useEffect(() => {
        if (addcartLoading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Product added in the Cart Successfully!");
            let page = 0;
            let limit = 0;
            let query = "";
            dispatch(cartProductList({ page, limit, query }));
            dispatch(clearLoadings());
        }
        if (errorMessage?.message) toast.error(errorMessage?.message);
        else if (errorMessage?.errors) {
            let data = "";
            console.log(errorMessage, "errorMessage");
            errorMessage?.errors.map((err) => {
                data += err.message + " ";
            });
            toast.error(data);
            // dispatch(clearLoadings());
        }
    }, [addcartLoading, errorMessage]);

    return (
        <>
            <Header />

            {/* <!-- SECTION START  --> */}

            <section class="pad-80 product_listings">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="sidebar">
                                <div class="sidebar-section">
                                    <div class="sidebar-wrapper">
                                        <h5 class="wrapper-heading">Product Categories</h5>
                                        <div class="sidebar-item">
                                            <ul class="sidebar-list">
                                                {categoryDatas &&
                                                    categoryDatas.map((row, index) => {
                                                        return (
                                                            <li>
                                                                <input type="checkbox" id={row?.id} name="category[]"
                                                                    onClick={handleOnSearch} />&nbsp;&nbsp;
                                                                <label for={row?.id}>{row?.name}</label>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                    <br/>
                                    <div class="sidebar-wrapper sidebar-range">
                                        <h5 class="wrapper-heading">Price Range</h5>
                                        <div class="price-slide range-slider">
                                            <div class="price">
                                                <div class="range-wrap">
                                                    <div class="range-value range-valueX" id="rangeV" ref={rangeVRef}><span>{prices}</span></div>
                                                    <input type="range" ref={rangeRef} min={settingDatas?.min_range ?? 200} max={settingDatas?.max_range ?? 800} defaultValue={800} onChange={handleChange} step="1" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-lg-9">
                            <h3 class="section-title">Our Products</h3>
                            {showdata == 1 ?
                                <div className="row">
                                    {productFilterDatas?.length > 0 ?
                                        productFilterDatas.map((row, index) => {
                                            return (
<>
                                                <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-4">
                                                     <div class="product__item white-bg mb-30">
                                                         <div class="product__thumb p-relative">
                                                             <div class="w-img">
                                                                 <img class="w-100" src={API_BASE + row?.image}  />
                                                                 <img class="second-img" src={API_BASE + row?.image} />
                                                             </div>
                                                             {/* <div class="product__action p-absolute">
                                                                 <ul>
                                                                     <li><a href="#" title="Add to Wishlist"><i class="fal fa-heart"></i></a></li>
                                                                     <li><a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#productModalId"><i class="fal fa-search"></i></a></li>
                                                                     <li><a href="#" title="Compare"><i class="far fa-sliders-h"></i></a></li>
                                                                 </ul>
                                                             </div> */}
                                                         </div>
                                                         <div class="product__content text-center">
                                                             <h6 class="product-name">
                                                                <Link class="product-item-link" to={'/product_details/' + row.id + '/' + row.catgory_id} > {row?.name}</Link>
                                                             </h6>
                                                             <div class="rating">
                                                                 <ul>
                                                                     <li><a href="#"><i class="far fa-star"></i></a></li>
                                                                     <li><a href="#"><i class="far fa-star"></i></a></li>
                                                                     <li><a href="#"><i class="far fa-star"></i></a></li>
                                                                     <li><a href="#"><i class="far fa-star"></i></a></li>
                                                                     <li><a href="#"><i class="far fa-star"></i></a></li>
                                                                 </ul>
                                                             </div>
                                                             <span class="product-prev-price">SAR {row?.reg_price}</span>
                                                             <span class="product-price price">SAR {row?.sale_price}</span>
                                                         </div>

                                                         <div class="product__add-btn">
                                                            <button class="btn custom-btn" onClick={() => addcartProducts(row)}> Add to Cart</button>
                                                         </div>
                                                     </div>
                                                </div>
                                                
                                                {/* <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4">
                                                    <div class="product">
                                                        <div class="product-img">
                                                            <img class="w-100" src={API_BASE + row?.image} alt="" />
                                                        </div>
                                                        <div class="product-content">
                                                            <div class="product-details">
                                                                <div class="product-name">
                                                                    <Link to={'/product_details/' + row.id + '/' + row.catgory_id} > {row?.name}</Link>
                                                                </div>
                                                                <div class="product-flex">
                                                                    <div class="left">
                                                                        <span class="product-prev-price">SAR {row?.reg_price}</span>
                                                                        <span class="product-price">SAR {row?.sale_price}</span>
                                                                    </div>
                                                                    <div class="right">
                                                                        <button class="btn custom-btn" onClick={() => addcartProducts(row)}> Add</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div> */}
                                                </>
                                            );
                                        })
                                        : <div>No Products...</div>
                                    }
                                </div>

                                : <div className="row">

                                    {productDatas &&
                                        productDatas.map((row, index) => {
                                            return (

                                                <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-4">
                                                     <div class="product__item white-bg mb-30">
                                                         <div class="product__thumb p-relative">
                                                             <div class="w-img">
                                                                <img class="w-100" src={API_BASE + row?.image} alt="" />
                                                                 <img class="second-img" src={API_BASE + row?.image} alt="" />
                                                             </div>
                                                             {/* <div class="product__action p-absolute">
                                                                 <ul>
                                                                     <li><a href="#" title="Add to Wishlist"><i class="fal fa-heart"></i></a></li>
                                                                     <li><a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#productModalId"><i class="fal fa-search"></i></a></li>
                                                                     <li><a href="#" title="Compare"><i class="far fa-sliders-h"></i></a></li>
                                                                 </ul>
                                                             </div> */}
                                                         </div>
                                                         <div class="product__content text-center">
                                                             <h6 class="product-name">
                                                                <Link class="product-item-link" to={'/product_details/' + row.id + '/' + row.catgory_id} >
                                                             {row?.name}</Link>
                                                             </h6>
                                                             <div class="rating">
                                                                 <ul>
                                                                     <li><a href="#"><i class="far fa-star"></i></a></li>
                                                                     <li><a href="#"><i class="far fa-star"></i></a></li>
                                                                     <li><a href="#"><i class="far fa-star"></i></a></li>
                                                                     <li><a href="#"><i class="far fa-star"></i></a></li>
                                                                     <li><a href="#"><i class="far fa-star"></i></a></li>
                                                                 </ul>
                                                             </div>
                                                             <span class="product-prev-price">SAR {row?.reg_price}</span>
                                                            <span class="product-price">SAR {row?.sale_price}</span>
                                                         </div>

                                                         <div class="product__add-btn">
                                                            <button class="btn custom-btn" onClick={() => addcartProducts(row)}> Add to Cart</button>
                                                         </div>
                                                     </div>
                                                </div>
                                            );
                                        })}

                                </div>}
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- SECTION END --> */}
            <Footer />
        </>
    )
};

export default Products